import { ConfigProvider } from 'antd'
import { BrowserRouter } from 'react-router-dom'
import type { RenderProps } from '.'
import Routes from './routes'
import { AppShowType, SessionStorageKeys } from '@/constants'
import TransactionDetail from '@/pages/TransactionDetail'
import { useFetchStoreInfo } from './services/store'
import { useCallback, useEffect } from 'react'

import { Provider } from 'react-redux'
import { StoreContext } from 'redux-react-hook'
import store from './pages/Order/redux/store'
import NowApp from './pages/Order/layout/NowApp'

function App(props: RenderProps) {
  const { pathPrefix, baseBridge } = props
  const { data, showType, onClose } = baseBridge?.getAppData() || {};
  (window as any).bridgeData = data
  const { PREFIXCLS, PREFIXCLS_ICON } = process.env
  const routePath = window.location.pathname

  // NOTE: 解决 单独访问子应用时 message 显示问题
  ConfigProvider.config({ prefixCls: PREFIXCLS })

  const { run: fetchStoreInfo } = useFetchStoreInfo({
    tobUserId: data?.user?.userId,
  })
  const setMerchantId = useCallback(async () => {
    const { data: storeInfo } = await fetchStoreInfo()
    sessionStorage.setItem(SessionStorageKeys.MerchantId, storeInfo?.merchantId?.toString() || '')
  }, [fetchStoreInfo])
  useEffect(() => {
    if (data?.user?.userId && !routePath.includes('/order/')) {
      setMerchantId()
    }
  }, [setMerchantId, data?.user?.userId, routePath])

  // 指定vn对应的路由，由react-redux提供状态管理
  // FIXME: 中间方案，在完全融合以后去掉这个逻辑
  if (routePath.includes('/order/')) {
    return (
      <ConfigProvider prefixCls={PREFIXCLS} iconPrefixCls={PREFIXCLS_ICON}>
        <Provider store={store}>
          <StoreContext.Provider value={store}>
            <BrowserRouter basename={pathPrefix}>
              <NowApp />
            </BrowserRouter>
          </StoreContext.Provider>
        </Provider>
      </ConfigProvider>
    )
  }

  // TODO: 根据showType判断是页面还是弹窗，本期只有一个弹窗暂时先写死，后期需主应用传对应的弹窗标识做分发
  if (showType === AppShowType.Modal) {
    return (
      <ConfigProvider prefixCls={PREFIXCLS} iconPrefixCls={PREFIXCLS_ICON}>
        <TransactionDetail onClose={onClose} {...data.props} />
      </ConfigProvider>
    )
  }
  return (
    <ConfigProvider prefixCls={PREFIXCLS} iconPrefixCls={PREFIXCLS_ICON}>
      <BrowserRouter basename={pathPrefix}>
        <Routes />
      </BrowserRouter>
    </ConfigProvider>
  )
}

export default App
